import { template as template_095e142ac89d499e96d441756d41756d } from "@ember/template-compiler";
const SidebarSectionMessage = template_095e142ac89d499e96d441756d41756d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
