import { template as template_5efb03bb368546ee87f2e13be7c3418d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5efb03bb368546ee87f2e13be7c3418d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
