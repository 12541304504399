import { template as template_2b3afaf6d39544b396be27c0b9d6a833 } from "@ember/template-compiler";
const FKLabel = template_2b3afaf6d39544b396be27c0b9d6a833(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
