import { template as template_fc18e007923343a186492eece3295a7f } from "@ember/template-compiler";
const FKText = template_fc18e007923343a186492eece3295a7f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
