import { template as template_069ab4abbe4742cc81e9ec0ad03a71e7 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PostMenuShowMoreButton extends Component {
    static shouldRender(args) {
        return args.state.collapsedButtons.length && args.state.collapsed;
    }
    static{
        template_069ab4abbe4742cc81e9ec0ad03a71e7(`
    <DButton
      class="post-action-menu__show-more show-more-actions"
      ...attributes
      @action={{@buttonActions.showMoreActions}}
      @icon="ellipsis"
      @title="show_more"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
